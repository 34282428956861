import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const Section = styled.section`

  a, button {
    outline: none!important;
    border: none!important;
    color: ${primary[500]};

    &:hover,
    &:focus {
      color: ${primary[500]};
    }
  }
  
  .content {
    background-color: ${primary[500]};
    border-radius: 130px 60px 130px 60px;
    padding: 40px 30px 50px 40px;
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-marketplace/image.webp');
    height: 375px;
    background-position: -124px 0;
    background-repeat: no-repeat;
    background-size: contain;

    a {
      max-width: 336px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    height: 424px;
    background-position: 0 0;

    a {
      max-width: 376px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    height: 591px;
    background-size: contain;
    background-position: top left;

    a {
      max-width: 311px;
    }
  }
`
