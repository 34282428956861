import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'
import { white } from 'src/styles/colors'

export const Section = styled.section`
  .bg {
   @media (min-width: ${breakpoints.md}) { 
      background-color: ${white};
    }
  }  

  @media (min-width: ${breakpoints.md}){ 
    background-color: transparent;
  }
  
  @media (min-width: ${breakpoints.md}){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra9-mobile-marketplace/image.webp');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    height: 355px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 413px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.xl}){
    height: 611px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    height: 940px;
    background-size: cover;
  }

  a {
    background-color: ${primary[500]};
    color: ${white};

    &:hover,
    &:focus {
      color: ${white};
      opacity: 0.9;
    }

    @media (min-width: ${breakpoints.md}){
      max-width: 100%;
    }
  }
`
