import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Link from 'src/components/GatsbyLinkWrapper/index'

import { Section } from './style'

const Recharge = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      className='py-5 bg-gray d-flex align-items-md-center'
      role='img'
      aria-label='Um homem jovem sorridente olhando pro lado enquanto segura o celular com a mão direita'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='bg rounded-5 px-3 px-lg-4 py-4 py-lg-5'>
              <h2 className='fs-24 fs-lg-28 fs-xl-48 lh-md-29 lh-lg-33 lh-xl-52 text-grayscale--500 fw-500'>
                Super recarga Inter
              </h2>
              <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400 mt-3'>
                Faça recarga de celular, de TV e de vale-transporte direto pelo Super App com muita segurança e em poucos cliques.
              </p>
              <Link
                title='Recarregar agora'
                className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-1'
                to='https://intergo.app/0ff673e4'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Recarregar agora',
                    section_name: 'Super recarga Inter',
                    redirect_url: 'https://intergo.app/0ff673e4',
                  })
                }}
              >
                Recarregar agora
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Recharge
