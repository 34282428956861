import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'
import { white } from 'src/styles/colors'

export const Section = styled.section`
  a {
    background-color: ${primary[500]};
    color: ${white};

    &:hover,
    &:focus {
      color: ${white};
      opacity: 0.9;
    }

    @media (min-width: ${breakpoints.md}){
      background-color: ${primary[500]};
      color: ${white};
      max-width: 336px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 376px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 454px;
    }
  }
`
