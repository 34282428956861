import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import pageContext from './pageContext.json'

// Sections
import Hero from './sections/hero/_index'
import Shopping from './sections/shopping/_index'
import GiftCard from './sections/gift-card/_index'
import Recharge from './sections/recharge/_index'
import CellPhonePlan from './sections/cell-phone-plan/_index'
import InternationalChip from './sections/international-chip/_index'
import GlobalShopping from './sections/global-shopping/_index'
import DuoGourmet from './sections/duo-gourmet/_index'
import Cashback from './sections/cashback/_index'
import GamerWorld from './sections/gamer-world/_index'
import Fuel from './sections/fuel/_index'
import Tips from './sections/tips/_index'

import useDomReady from 'src/hooks/window/useDomReady'
import QrCodeDiaADia from './assets/images/qr-code-dia-a-dia.png'

import { Wrapper } from './style'

const DiaADia = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Guardar dinheiro é simples!',
    element_action: 'click button',
    element_name: 'Guardar meu dinheiro',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o Super App e aproveite'
        subtitle='Ofertas exclusivas pelo Super App. Leia o QR code e seja direcionado'
        instructions='Aponte a câmera do celular para o QR Code e baixe o Super Ap na Loja de Aplicativos.'
        qrCode={QrCodeDiaADia}
        dataLayer={dataLayer}
        isHrBottom
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openModal}
        <Hero setOpenModal={setIsOpen} dataLayer={dataLayer} setDataLayer={setDataLayer} />
        <Shopping />
        <GiftCard />
        <Recharge />
        <CellPhonePlan />
        <InternationalChip />
        <GlobalShopping />
        <DuoGourmet />
        <Cashback />
        <GamerWorld />
        <Fuel />
        <Tips />
      </Layout>
    </Wrapper>
  )
}

export default DiaADia
